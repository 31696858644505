<template>
  <fieldset class="catch">
    <Popup
      v-model="catchSpecie['remark']"
      :title-label="$t('editTrip.remarkTitle')"
      :maxlength="2000"
      :modalName="uid"
    />
    <Select
      v-if="!selectedSpecie"
      :label="$t('editTrip.effortStep.specie')"
      v-model="selectedSpecie"
      :options="availableSpecies"
      :required="true"
      @error="addError"
      :refeshError="showErrorData"
    />
    <h4 class="level3" v-else>
      {{ title }}
      <span
        v-if="option && !option.isSpecieOther"
        v-b-modal="uid"
        class="add-remark-btn-title catch"
      ></span>
    </h4>

    <GenericInput
      v-if="option?.hasCatchOtherSpeciesDescription"
      v-model="catchSpecie.specieOtherDescription"
      :label="$t('catch.specieDesc')"
      :required="
        option.hasCatchOtherSpeciesDescription === $const.MANDATORY &&
          hasOneMainField
      "
      :refeshError="showErrorData"
      type="text"
      maxlength="50"
      @error="addError"
    />
    <GenericInput
      v-if="option?.hasCatchTagNum"
      :label="$t('catch.tagNumber')"
      v-model="catchSpecie['tagNumber']"
      :required="option.hasCatchTagNum === $const.MANDATORY && hasOneMainField"
      :maxlength="40"
      type="text"
      @error="addError"
      :refeshError="showErrorData"
    />

    <!-- TODO: Harcoded min/max !?! -->
    <GeopositionWidget
      v-if="option?.hasCatchPosition"
      :position="catchSpecie.position"
      @binding="
        function changeValue(value) {
          catchSpecie.position = { ...catchSpecie.position, ...value };
        }
      "
      :dissableSSSections="true"
      :required="
        option.hasCatchPosition === $const.MANDATORY && hasOneMainField
      "
      :minLat="[38.0]"
      :maxLat="[72.0]"
      :minLong="[-148.0]"
      :maxLong="[-40.0]"
      @error="addError"
      :refeshError="showErrorData"
    />
    <UnitConverter
      v-if="option?.hasCatchCaughtWeight"
      :label="$t('catch.caughtWeight')"
      v-model="catchSpecie.caughtWeight"
      :required="option.hasCatchCaughtWeight === $const.MANDATORY"
      :min="0"
      :max="999999.999"
      unit="weight"
      :forceErrorMsg="forceError"
      @error="addError"
      :refresh="showErrorData"
    />
    <UnitConverter
      v-if="showKeptWeight"
      :label="$t('catch.keptWeight')"
      v-model="catchSpecie['keptWeight']"
      :required="requireKeptWeight"
      :min="0"
      :max="999999.999"
      unit="weight"
      :forceErrorMsg="forceError"
      @error="addError"
      :refresh="showErrorData"
    />
    <IntegerInput
      v-if="option?.hasCatchSpecimenKept"
      :label="$t('catch.keptNbSpecimen')"
      v-model="catchSpecie['keptNbSpecimen']"
      :required="requireKeptSpecimen"
      :min="0"
      :max="9999"
      :forceErrorMsg="forceError"
      @error="addError"
      :refeshError="showErrorData"
    />
    <LabelView
      v-if="
        option?.hasAverageSpecimenWeight &&
          showKeptWeight &&
          option?.hasCatchSpecimenKept
      "
      :label="$t('catch.keptAvg')"
      :value="averageKeptWeight"
      :floatLeft="true"
      type="text"
    />
    <UnitConverter
      v-if="option?.hasDiscWeight"
      :label="$t('catch.discWeight')"
      v-model="catchSpecie['discWeight']"
      :required="requireDiscWeight"
      :min="0"
      :max="999999.999"
      unit="weight"
      :forceErrorMsg="forceError"
      @error="addError"
      :refresh="showErrorData"
    />
    <IntegerInput
      v-if="option?.hasCatchSpecimenDisc"
      :required="requireDiscSpecimen"
      :label="$t('catch.discNbSpecimen')"
      :placeholder="discSpecimenPlaceholder"
      v-model="catchSpecie['discNbSpecimen']"
      :min="0"
      :max="9999"
      :forceErrorMsg="forceError"
      @error="addError"
      :refeshError="showErrorData"
    />
    <LabelView
      v-if="
        option?.hasAverageSpecimenWeight &&
          option?.hasDiscWeight &&
          option?.hasCatchSpecimenDisc
      "
      :label="$t('catch.discAvg')"
      :value="averageDiscWeight"
      :floatLeft="true"
      type="text"
    />
    <UnitConverter
      v-if="option?.hasSpecimenKept2"
      :label="$t('catch.keptWeight2')"
      v-model="catchSpecie['keptWeight2']"
      :required="option.hasSpecimenKept2 === $const.MANDATORY || isTarget"
      :min="0"
      :max="999999.999"
      unit="weight"
      @error="addError"
      :refresh="showErrorData"
    />
    <IntegerInput
      v-if="option?.hasBerried"
      :label="$t('catch.berried')"
      v-model="catchSpecie['berried']"
      :required="option.hasBerried === $const.MANDATORY && hasOneMainField"
      :min="0"
      :max="9999"
      @error="addError"
      :refeshError="showErrorData"
    />
    <IntegerInput
      v-if="option?.hasCatchSpecimenCaught"
      :label="$t('catch.specimenCaught')"
      v-model="catchSpecie['specimenCaught']"
      :required="option.hasCatchSpecimenCaught === $const.MANDATORY"
      :min="0"
      :max="9999"
      :forceErrorMsg="forceError"
      @error="addError"
      :refeshError="showErrorData"
    />
    <IntegerInput
      v-if="option?.hasSpecimenDiscInfected"
      :label="$t('catch.specimenDiscInfected')"
      v-model="catchSpecie['specimenDiscInfected']"
      :required="
        option.hasSpecimenDiscInfected === $const.MANDATORY && hasOneMainField
      "
      :min="0"
      :max="9999"
      @error="addError"
      :refeshError="showErrorData"
    />
    <IntegerInput
      v-if="option?.hasCatchSpecimenHooked"
      :label="$t('catch.specimenHook')"
      v-model="catchSpecie['specimenHook']"
      :required="
        option.hasCatchSpecimenHooked === $const.MANDATORY && hasOneMainField
      "
      :min="0"
      :max="9999"
      :forceErrorMsg="validSpecimenCaught"
      @error="addError"
      :refeshError="showErrorData"
    />
    <IntegerInput
      v-if="option?.hasCatchSpecimenHooked"
      :label="$t('catch.specimenEntangled')"
      v-model="catchSpecie['specimenEntangled']"
      :required="
        option.hasCatchSpecimenHooked === $const.MANDATORY && hasOneMainField
      "
      :min="0"
      :max="9999"
      :forceErrorMsg="validSpecimenCaught"
      @error="addError"
      :refeshError="showErrorData"
    />
    <RadioInput
      v-if="option?.hasCatchMarketIndicator"
      v-model="catchSpecie['marketableIndicator']"
      :label="$t('catch.marketableIndicator')"
      :options="systemsLists.response"
      :refeshError="showErrorData"
      :required="
        option.hasCatchMarketIndicator === $const.MANDATORY && hasOneMainField
      "
      @error="addError"
    />
    <RadioInput
      v-if="option?.hasCatchLegalityIndicator"
      v-model="catchSpecie['legalityIndicator']"
      :forceErrorMsg="keptWeightIllegalError"
      :label="$t('catch.legalityIndicator')"
      :options="systemsLists.response"
      :refeshError="showErrorData"
      :required="
        option.hasCatchLegalityIndicator === $const.MANDATORY && hasOneMainField
      "
      @error="addError"
    />
    <UnitConverter
      v-if="option?.hasFlankLength"
      :label="$t('catch.flankLength')"
      v-model="catchSpecie['flankLength']"
      :required="option.hasFlankLength === $const.MANDATORY && hasOneMainField"
      :min="1"
      :max="999"
      unit="cm"
      @error="addError"
      :refresh="showErrorData"
    />
    <UnitConverter
      v-if="option?.hasDressedLength"
      :label="$t('catch.dressedLength')"
      v-model="catchSpecie['dressedLength']"
      :required="
        option.hasDressedLength === $const.MANDATORY && hasOneMainField
      "
      :min="1"
      :max="999"
      unit="cm"
      @error="addError"
      :refresh="showErrorData"
    />
    <Select
      v-if="option?.hasSpecimenCondition"
      :label="$t('catch.specimenCondition')"
      :options="systemsLists.sarCondition"
      v-model="catchSpecie['specimenCondition']"
      :required="
        option.hasSpecimenCondition === $const.MANDATORY && hasOneMainField
      "
      @error="addError"
      :refeshError="showErrorData"
    />
    <Select
      v-if="option?.hasCatchSpecieSize"
      v-model="catchSpecie['productSize']"
      :label="$t('catch.size')"
      :options="systemsLists.productSize"
      :required="
        option.hasCatchSpecieSize === $const.MANDATORY && hasOneMainField
      "
      :refeshError="showErrorData"
      @error="addError"
    />
    <GenericInput
      v-if="option?.hasCatchMarketDescription"
      v-model="catchSpecie['marketDescription']"
      :label="$t('catch.marketDescription')"
      :required="
        option.hasCatchMarketDescription === $const.MANDATORY && hasOneMainField
      "
      :refeshError="showErrorData"
      type="text"
      maxlength="50"
      @error="addError"
    />
    <UnitConverter
      v-if="option?.hasCatchBabyDiscWt"
      v-model="catchSpecie['babyDiscWt']"
      :label="$t('catch.babyDiscWt')"
      :required="
        option.hasCatchBabyDiscWt === $const.MANDATORY && hasOneMainField
      "
      :min="0"
      :max="999999.999"
      :refresh="showErrorData"
      unit="weight"
      @error="addError"
    />
    <Select
      v-if="option?.hasCatchEncounterType"
      v-model="catchSpecie['encounterType']"
      :label="$t('catch.encounterType')"
      :options="systemsLists.encounterType"
      :required="
        option.hasCatchEncounterType === $const.MANDATORY && hasOneMainField
      "
      :refeshError="showErrorData"
      @error="addError"
    />
    <GenericInput
      v-if="option?.hasCatchRoeStage"
      v-model="catchSpecie['roeStageDescription']"
      :label="$t('catch.roeStageDescription')"
      :required="
        option.hasCatchRoeStage === $const.MANDATORY && hasOneMainField
      "
      :refeshError="showErrorData"
      type="text"
      maxlength="50"
      @error="addError"
    />
    <RadioInput
      v-if="option?.hasCatchRoeIndicator"
      v-model="catchSpecie['hasRoes']"
      :label="$t('catch.roeIndicator')"
      :options="systemsLists.response"
      :refeshError="showErrorData"
      :required="
        option.hasCatchRoeIndicator === $const.MANDATORY && hasOneMainField
      "
      @error="addError"
    />
    <RadioInput
      v-if="option?.hasCatchSampleIndicator"
      v-model="catchSpecie['sampleIndicator']"
      :label="$t('catch.sampleIndicator')"
      :options="systemsLists.response"
      :refeshError="showErrorData"
      :required="
        option.hasCatchSampleIndicator === $const.MANDATORY && hasOneMainField
      "
      @error="addError"
    />
    <IntegerInput
      v-if="option?.hasCatchFemalePercentage"
      v-model="catchSpecie['femalePercentage']"
      :required="
        option.hasCatchFemalePercentage === $const.MANDATORY && hasOneMainField
      "
      :label="$t('catch.femalePercentage')"
      :min="0"
      :max="100"
      :refeshError="showErrorData"
      @error="addError"
    />
    <GenericInput
      v-if="option?.isSpecieOther"
      :label="$t('editTrip.remark')"
      v-model="catchSpecie['remark']"
      type="text"
      :maxlength="2000"
      @error="addError"
      :refeshError="showErrorData"
      :required="true"
    />
    <Details
      v-if="option?.catchDetailMode === $const.CATCH_DETAIL_MODE_PRAWN"
      :showErrorData="showErrorData"
      :addError="addError"
      :details="catchSpecie.details"
      :catch="catchSpecie"
    />
  </fieldset>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import GenericInput from "@/components/GenericInput.vue";
import Select from "@/components/Select.vue";
import IntegerInput from "@/components/subformEditTrip/widgets/IntegerInput.vue";
import LabelView from "@/components/LabelView.vue";
import GeopositionWidget from "@/components/widgets/GeopositionWidget.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";
import Details from "./Details.vue";
import Popup from "@/components/widgets/Popup.vue";
import RadioInput from "@/components/widgets/RadioInput.vue";

let catchEditCounter = 0;

export default {
  name: "CatchEdit",
  components: {
    IntegerInput,
    LabelView,
    GenericInput,
    GeopositionWidget,
    Select,
    UnitConverter,
    Popup,
    RadioInput,
    Details
  },
  props: {
    showErrorData: Number, // serve as trigger to tell the fields to add an error if the value is invalid
    addError: Function,
    effort: Object,
    options: Object,
    initialCatch: Object,
    species: Array,
    exclude: Array,
    isTarget: Boolean
  },
  data() {
    return {
      uid: null,
      option: null,
      selectedSpecie: null,
      catchSpecie: {
        species: null,
        specieOtherDescription: null,
        remark: null,
        keptNbSpecimen: null,
        keptWeight: null,
        discNbSpecimen: null,
        discWeight: null,
        berried: null,
        tagNumber: null,
        caughtWeight: null,
        specimenCaught: null,
        specimenDiscInfected: null,
        specimenHook: null,
        specimenEntangled: null,
        marketableIndicator: null,
        legalityIndicator: null,
        dressedLength: null,
        specimenCondition: null,
        keptWeight2: null,
        flankLength: null,
        productForm2: null,
        encounterType: null,
        productSize: null,
        marketDescription: null,
        babyDiscWt: null,
        roeStageDescription: null,
        hasRoes: null,
        sampleIndicator: null,
        femalePercentage: null,
        details: [],
        position: {
          latitude: null,
          longitude: null,
          grid: null,
          lgrid: null,
          nafo: null,
          ssSecteur: null
        }
      },
      catchInitialized: false,
      refreshKey: 0
    };
  },
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      systemsLists: state => state.systemsLists,
      subform: state => state.editTripSubform.subform
    }),
    hasTargetKeptWeight() {
      return this.getPropertyValue("hasTargetKeptWeight", {
        subforms: this.subform
      });
    },
    availableSpecies() {
      return this.species.filter(s => {
        if (s.value == null) return true;
        return !this.exclude.includes(s.value);
      }, this);
    },
    validSpecimenCaught() {
      const caught = this.catchSpecie.specimenCaught;
      const hooked = this.catchSpecie.specimenHook;
      const entangled = this.catchSpecie.specimenEntangled;
      if (caught && hooked && entangled) {
        if (caught != hooked + entangled) {
          return `${hooked} + ${entangled} = ${caught} ?`;
        }
      }
      return undefined;
    },
    showKeptWeight() {
      let show = this.option?.hasCatchKeptWeight !== this.$const.BLOCKED;
      if (!show && this.isTarget && this.hasTargetKeptWeight) {
        // if a form does not allow target specie to report a keptWeight, we will need a switch
        // this is needed to support Form 235 rules 3007 exceptions

        // exception for artic, they dont use keptWeight (hasTargetKeptWeight)
        show = true;
      }
      return show;
    },
    discSpecimenPlaceholder() {
      return !this.requireDiscSpecimen &&
        this.catchSpecie.species === this.$const.LOBSTER
        ? this.$t("optional")
        : "";
    },
    requireKeptWeight() {
      const needsBothFields =
        this.option?.hasWeightAndSpecimenCombo === this.$const.MANDATORY;
      let required = this.option?.hasCatchKeptWeight === this.$const.MANDATORY;
      required ||= this.isTarget;

      if (!required && needsBothFields) {
        // Form 235, rule 3006
        if (this.catchSpecie.keptNbSpecimen !== null) {
          required = true;
        }
      }
      return required;
    },
    requireDiscWeight() {
      const needsBothFields =
        this.option?.hasWeightAndSpecimenCombo === this.$const.MANDATORY;
      let required = this.option?.hasDiscWeight === this.$const.MANDATORY;

      if (!required && needsBothFields) {
        // Form 235, rule 3006
        if (this.catchSpecie.discNbSpecimen !== null) {
          required = true;
        }
      }
      return required;
    },
    requireKeptSpecimen() {
      const needsBothFields =
        this.option?.hasWeightAndSpecimenCombo === this.$const.MANDATORY;
      let required =
        this.option?.hasCatchSpecimenKept === this.$const.MANDATORY;

      if (!required && needsBothFields) {
        // Form 235, rule 3006
        if (this.catchSpecie.keptWeight !== null) {
          required = true;
        }
      }
      return required;
    },
    requireDiscSpecimen() {
      const needsBothFields =
        this.option?.hasWeightAndSpecimenCombo === this.$const.MANDATORY;
      let required =
        this.option?.hasCatchSpecimenDisc === this.$const.MANDATORY;

      if (!required && needsBothFields) {
        // Form 235, rule 3006
        if (this.catchSpecie.discWeight !== null) {
          required = true;
        }
      }
      return required;
    },
    averageKeptWeight() {
      const weight = this.catchSpecie.keptWeight;
      const quantity = this.catchSpecie.keptNbSpecimen;
      if (weight && quantity) {
        const value = weight / quantity;
        const pow = Math.pow(10.0, 4);
        return Math.round(value * pow) / pow + " kg";
      }
      return "-";
    },
    averageDiscWeight() {
      const weight = this.catchSpecie.discWeight;
      const quantity = this.catchSpecie.discNbSpecimen;
      if (weight && quantity) {
        const value = weight / quantity;
        const pow = Math.pow(10.0, 4);
        return Math.round(value * pow) / pow + " kg";
      }
      return "-";
    },
    productFormId() {
      // converting to calculated field because of 226 rule 801
      // always ROUND or NULL based on property 'hasSpecieFrmId'
      // exception if catchProductFormSpecial and null weight :P
      let form = null;
      if (this.option && this.option?.hasSpecieFrmId !== this.$const.BLOCKED) {
        if (
          this.option.catchProductFormSpecial !== this.$const.BLOCKED &&
          this.catchSpecie.keptWeight === null &&
          this.catchSpecie.discWeight === null
        ) {
          form = null;
        } else {
          form = this.$const.FORM_ROUND;
        }
      }

      return form;
    },
    title() {
      const formId = this.productFormId;
      const specie = this.systemsLists.species.find(
        x => x.value == this.catchSpecie.species
      );
      let result;

      if (specie) {
        result = specie.text;
        if (formId) {
          const form = this.systemsLists.productForm.find(
            x => x.value == formId
          );
          if (form) {
            result = `${result} (${form.text})`;
          }
        }
      }

      return result;
    },
    hasOneMainField() {
      // General rule
      // In each occurrence of node CATCH, at least one quantity must be declared.
      // So, at least one of these elements must contain a value greater than or equal to 0.
      return (
        this.catchSpecie.keptWeight !== null ||
        this.catchSpecie.keptNbSpecimen !== null ||
        this.catchSpecie.discNbSpecimen !== null ||
        this.catchSpecie.specimenCaught !== null ||
        this.catchSpecie.discWeight !== null
      );
    },
    forceError() {
      if (this.catchInitialized && !this.hasOneMainFieldRequired) {
        if (!this.hasOneMainField) {
          return this.$t("validator.atLeastOneValueRequired");
        }
      }
      return null;
    },
    keptWeightIllegalError() {
      if (
        this.catchSpecie.legalityIndicator === this.$const.NO &&
        this.catchSpecie.keptWeight > 0
      ) {
        return this.$t("catch.legalityError");
      }
      return null;
    },
    hasOneMainFieldRequired() {
      return (
        this.requireKeptWeight ||
        this.requireKeptSpecimen ||
        this.requireDiscSpecimen ||
        this.option?.hasCatchSpecimenCaught === this.$const.MANDATORY ||
        this.requireDiscWeight
      );
    }
  },
  watch: {
    selectedSpecie: function() {
      if (process.env.VUE_APP_DEBUG_CATCH) {
        // eslint-disable-next-line no-console
        console.debug("CatchEdit: selected specie", this.selectedSpecie);
      }
      this.catchSpecie.species = this.selectedSpecie;
      if (this.catchSpecie.species) {
        this.option = this.options[this.selectedSpecie];

        if (this.option.hasSpecimenKept2 === this.$const.MANDATORY) {
          this.catchSpecie.productForm2 = this.$const.FORM_DRESSED;
        }
        this.$emit("specie", this.selectedSpecie);
      } else {
        this.option = null;
      }
    },
    catchSpecie: {
      handler: function() {
        if (process.env.VUE_APP_DEBUG_CATCH) {
          // eslint-disable-next-line no-console
          console.debug("CatchEdit: form watch");
        }

        if (!this.catchSpecie.species) return;
        if (!this.hasOneMainField) return;
        this.catchInitialized = true;

        // modify a copy so we dont trigger a second watch everytime
        const data = Object.assign({}, this.catchSpecie);
        data.productForm = this.productFormId;

        this.$emit("update", data);
      },
      deep: true
    }
  },
  beforeMount() {
    this.uid = "catchedit-" + catchEditCounter;
    catchEditCounter += 1;

    Object.assign(this.catchSpecie, this.initialCatch);

    if (this.catchSpecie.species) {
      this.selectedSpecie = this.catchSpecie.species;
    }

    if (process.env.VUE_APP_DEBUG_CATCH) {
      // eslint-disable-next-line no-console
      console.debug("CatchEdit: mount", this.catchSpecie, this.option);
    }
  },
  destroyed() {
    if (process.env.VUE_APP_DEBUG_CATCH) {
      // eslint-disable-next-line no-console
      console.debug("CatchEdit: specie not used anymore", this.selectedSpecie);
    }
    this.$emit("specie", 0 - this.selectedSpecie);
  }
};
</script>
