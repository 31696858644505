<template>
  <div class="list" :name="$options.name">
    <h3 class="step-title">
      <span>
        <span>
          <span>{{ $t("editTrip.speciesAtRiskStep.title1") }}</span>
        </span>
      </span>
    </h3>
    <fieldset
      v-for="(sar, index) in form.speciesAtRisk"
      :key="index"
      class="removeBottomPadding"
    >
      <h4 class="level1">
        {{ $t("editTrip.speciesAtRiskStep.sarInteraction") }}
        {{ index + 1 }}
        <span
          v-b-modal="'suppSpeciesAtRiskEntry-' + index + '-remark'"
          v-show="sar['closeDatetime'] === undefined"
          class="add-remark-btn-title"
        ></span>
        <Popup
          v-model="sar['remark']"
          :title-label="$t('editTrip.remarkTitle')"
          :maxlength="2000"
          :modalName="'suppSpeciesAtRiskEntry-' + index + '-remark'"
        />
      </h4>
      <b-overlay
        :show="sar['closeDatetime'] !== undefined"
        rounded="sm"
        blur="none"
        :key="'specie' + index + sar['closeDatetime']"
      >
        <template #overlay>
          <b-icon icon="file-lock" variant="danger" scale="2"></b-icon>
          <p class="redMessage">{{ $t("closeMsg") }}</p>
          <p class="redMessage">
            {{ $t("closeMsgDate") }}{{ $showDate(sar["closeDatetime"]) }}
          </p>
        </template>
        <fieldset :disabled="sar['closeDatetime'] !== undefined">
          <Select
            :label="$t('editTrip.speciesAtRiskStep.species')"
            :options="sarOptions"
            v-model="sar['species']"
            :required="true"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshIndex[index]"
          />
          <br />
          <DateInput
            :label="$t('editTrip.speciesAtRiskStep.datetime')"
            v-model="sar.occursAt.datetime"
            :required="true"
            :autodatetime="true"
            :minDates="[
              {
                value: openTrip.departure['datetime'],
                text: $t('editTrip.beforeDepartureDate')
              }
            ]"
            :passDate="true"
            :key="'sarDate-' + index"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshIndex[index]"
          />
          <GeopositionWidget
            :position="sar['occursAt']"
            @binding="
              changeValue => {
                sar['occursAt'] = { ...sar['occursAt'], ...changeValue };
              }
            "
            :required="true"
            :minLat="[minSarLat]"
            :maxLat="[maxSarLat]"
            :minLong="[minSarLon]"
            :maxLong="[maxSarLon]"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshIndex[index]"
            :key="'sarGPS-' + index"
          />
          <br />
          <Select
            :label="$t('editTrip.speciesAtRiskStep.specimenCondition')"
            :options="systemsLists.sarCondition"
            v-model="sar['specimenCondition']"
            :required="true"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshIndex[index]"
            :key="'sarCondition-' + index"
          />
          <br />
          <IntegerInput
            :label="$t('editTrip.speciesAtRiskStep.nbSpecimen')"
            v-model="sar['nbSpecimen']"
            :min="1"
            :max="9999"
            :required="true"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshIndex[index]"
            :key="'sarNbSpecimen-' + index"
          />
          <UnitConverter
            :label="$t('editTrip.speciesAtRiskStep.weight')"
            v-model="sar['weight']"
            :min="0"
            unit="weight"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshIndex[index]"
            :key="'sarWeight-' + index"
          />
        </fieldset>
      </b-overlay>
      <fieldset class="suppEntryButtonWrapper removeBottomPadding">
        <p>
          <input
            v-if="!sar['closeDatetime']"
            @click="closeSpeciesEvent(index)"
            class="closeSuppEntryButton"
            type="button"
            :class="
              index + 1 < form.speciesAtRisk.length
                ? 'addBottomMargin'
                : 'removeBottomMargin'
            "
            :value="$t('editTrip.speciesAtRiskStep.close') + String(index + 1)"
          />
        </p>
      </fieldset>
    </fieldset>
    <fieldset class="suppEntryButtonWrapper removeTopMargin">
      <p>
        <input
          v-if="
            form.speciesAtRisk.length > 1 &&
              !$isLastItemClosed(form.speciesAtRisk)
          "
          @click="removeSpeciesEvent()"
          class="removeSuppEntryButton"
          type="button"
          :value="
            $t('editTrip.speciesAtRiskStep.remove') +
              String(form.speciesAtRisk.length)
          "
        />
        <input
          @click="addSpeciesEvent()"
          class="addSuppEntryButton"
          type="button"
          :value="$t('editTrip.speciesAtRiskStep.add')"
        />
      </p>
    </fieldset>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { keysMatchingValue, scrollToTopField } from "@/utils/utils";
import Select from "@/components/Select.vue";
import IntegerInput from "@/components/subformEditTrip/widgets/IntegerInput.vue";
import GeopositionWidget from "@/components/widgets/GeopositionWidget.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";
import Popup from "@/components/widgets/Popup.vue";
import DateInput from "@/components/widgets/DateInput.vue";

const name = "speciesAtRiskStep";

export default {
  name: "SpeciesAtRiskStep",
  components: {
    GeopositionWidget,
    IntegerInput,
    Select,
    Popup,
    DateInput,
    UnitConverter
  },
  data() {
    return {
      species: {},
      mmiTypes: [],
      form: {
        speciesAtRisk: []
      },
      confirmationSar: false,
      confirmationMmi: false,
      showErrorData: 0,
      refreshIndex: [],
      isFirstTime: true
    };
  },
  computed: {
    ...mapGetters(["getPropertyValue", "buildOptions"]),
    ...mapState({
      openTrip: state => state.currentOpenTrip,
      userProfile: state => state.userProfile,
      refeshErrorByIndex: state => state.editTrip.refeshErrorByIndex,
      systemsLists: state => state.systemsLists,
      subscription: state => state.currentSubscription,
      currentPage: state => state.editTripSubform.currentPage,
      showError: state => state.editTripSubform.showError,
      isFinalReview: state => state.editTripSubform.isFinalReview,
      stateErrors: state => state.editTripSubform.errors
    }),
    sarSpecies() {
      return this.getPropertyValue(
        "sarSpecies",
        { forms: this.openTrip.form },
        []
      );
    },
    sarOptions() {
      return this.buildOptions(this.sarSpecies, this.systemsLists.sarSpecies);
    },
    minSarLat() {
      return this.getPropertyValue(
        "minSarLat",
        { form: this.openTrip.form, subforms: this.openTrip.subform },
        -90
      );
    },
    maxSarLat() {
      return this.getPropertyValue(
        "maxSarLat",
        { form: this.openTrip.form, subforms: this.openTrip.subform },
        90
      );
    },
    minSarLon() {
      return this.getPropertyValue(
        "minSarLon",
        { form: this.openTrip.form, subforms: this.openTrip.subform },
        -180
      );
    },
    maxSarLon() {
      return this.getPropertyValue(
        "maxSarLon",
        { form: this.openTrip.form, subforms: this.openTrip.subform },
        180
      );
    }
  },
  watch: {
    showError() {
      if (this.isFinalReview || this.currentPage === name) {
        ++this.showErrorData;
      }
    },
    form: {
      deep: true,
      handler: function() {
        if (!this.isFirstTime) this.$emit("update-form", this.form);
        this.isFirstTime = false;
      }
    },
    "openTrip.efforts": {
      deep: true,
      handler: function() {
        this.autoCreateSarEntries();
      }
    }
  },
  mounted() {
    if (this.openTrip["creation"] && this.openTrip.speciesAtRisk.length > 0) {
      this.form.speciesAtRisk = this.openTrip.speciesAtRisk ?? [];
    } else {
      this.autoCreateSarEntries();
    }
    this.refreshIndex = Array(this.form.speciesAtRisk.length).fill(0);
  },
  methods: {
    ...mapActions(["closeObject", "addErrorsToState"]),
    addError(error, index) {
      this.addErrorsToState({ page: `${name}-${index}`, errors: error });
      this.addErrorsToState({ page: name, errors: error });
    },
    addSpeciesEvent() {
      this.showErrorData += 1;
      this.$nextTick(() => {
        const errorsFieldsNames = keysMatchingValue(
          this.stateErrors,
          true,
          `${name}-${this.form.speciesAtRisk.length - 1}`
        );
        if (errorsFieldsNames.length > 0) {
          scrollToTopField(errorsFieldsNames);
          return;
        }
        this.form.speciesAtRisk.push(this.emptySpecieAtRisk());
        this.refreshIndex.push(0);
      });
    },
    removeSpeciesEvent() {
      this.form.speciesAtRisk.pop();
      this.refreshIndex.pop();
    },
    closeSpeciesEvent(index) {
      Vue.set(this.refreshIndex, index, this.refreshIndex[index] + 1);
      this.closeObject({
        value: this.form.speciesAtRisk,
        type: `${name}-${index}`,
        index,
        vm: this
      });
    },
    autoCreateSarEntries() {
      if (!this.openTrip.efforts || this.openTrip.efforts.length === 0) {
        return;
      }

      if (this.form.speciesAtRisk.length > 0) {
        return;
      }

      const speciesAtRisk = this.openTrip.efforts
        .filter(effort => effort.confirmationSar === this.$const.YES)
        .map(effort => this.emptySpecieAtRisk(effort.endPosition));

      if (speciesAtRisk.length !== this.form.speciesAtRisk.length)
        this.form.speciesAtRisk = speciesAtRisk;
      this.refreshIndex = Array(this.form.speciesAtRisk.length).fill(0);
    },
    emptySpecieAtRisk(position) {
      let sar = {
        remark: null,
        occursAt: {
          datetime: null,
          latitude: null,
          longitude: null,
          autolat: null,
          autolon: null
        },
        type: [],
        species: null,
        specimenCondition: null,
        nbSpecimen: null,
        weight: null
      };
      if (position) {
        sar.occursAt.autolat = position.autolat;
        sar.occursAt.autolon = position.autolon;
        sar.occursAt.latitude = position.latitude;
        sar.occursAt.longitude = position.longitude;
      }
      return sar;
    }
  }
};
</script>
