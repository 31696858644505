<template>
  <div id="">
    <div class="page insideApp" id="pageArchiveLobby" style="display: block;">
      <h1>{{ $t("header.archives") }}</h1>
      <div class="content">
        <p class="emptyAccount hide">Vous n'êtes pas inscris à aucun module.</p>
        <nav class="archiveMenu">
          <div
            v-for="subscription in subscriptions"
            :key="subscription.uuid"
            class="module-btn-wrapper"
          >
            <div
              @click="clickArchive(subscription.uuid)"
              class="module-btn-wrapper"
            >
              <a
                :class="
                  'module-link' + (subscription.module.isDemo ? ' demo' : '')
                "
                data-moduleindex="modules"
                data-subscription="4ed27673-8c57-41c4-a266-cdf598cafb65"
              >
                <img
                  class="icon"
                  :src="getImageData(subscription.module.icon)"
                />
                <span class="label">
                  <span>
                    <span>{{ subscription.module.label }}</span>
                  </span>
                </span>
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <div class="topPageMenu center">
      <br />
      <router-link :to="{ name: 'modules' }" v-html="$t('lobby.link')" />
    </div>
  </div>
</template>

<script>
import LOBBY_ICONS from "@/utils/lobby_icons";
export default {
  name: "ArchiveModuleList",
  computed: {
    elogKeyIsNeeded() {
      const profile = this.$store.getters.USER_PROFILE;
      return profile && !profile.hasElogKey;
    },
    elogKeyIsExpired() {
      const profile = this.$store.getters.USER_PROFILE;
      return profile && profile.isElogKeyExpired;
    },
    subscriptions() {
      return this.$store.getters.SUBSCRIPTIONS;
    },
    hasSubscriptions() {
      const subscriptions = this.$store.getters.SUBSCRIPTIONS;
      return subscriptions && subscriptions.length;
    },
    isConnectedOnline() {
      return this.$store.getters.IS_APP_CONNECTED_ONLINE;
    }
  },
  methods: {
    onlinePopupError() {
      this.$store.dispatch(
        "alert/error",
        this.$store.getters.GET_CURRENT_ONLINE_ERROR
      );
    },
    getImageData: function(name) {
      if (!name) return "";
      const language = this.$store.getters.LANGUAGE;
      return LOBBY_ICONS[name.format(language)];
    },
    clickArchive(uuid) {
      if (this.isConnectedOnline) {
        this.$router.push({
          name: "archiveList",
          params: { uuid: uuid }
        });
      } else {
        this.onlinePopupError();
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "archiveList") {
      const uuid = to.params.uuid;
      this.$store.dispatch("selectSubscription", uuid).then(next());
    } else {
      next();
    }
  }
};
</script>

<style lang="scss" scoped>
.center {
  text-align: center;
}
</style>
