<template>
  <div class="quota" v-if="calculateQuotas.length">
    <b-row
      v-for="(quota, index) in calculateQuotas"
      :key="index"
      style="margin:10px 5px;"
    >
      <b-col class="quotaLabel center" cols="12">
        <strong style="color:green;">
          {{ $t("fields.quota") }}
          {{ quota.maxWeight | round }}
          kg
        </strong>
      </b-col>
      <b-col cols="12">
        <b-progress :max="quota.maxWeight" height="2em">
          <b-progress-bar
            :value="quota.keptWeight"
            :precision="0"
            :variant="quota.color"
            show-value
          >
          </b-progress-bar>
          <b-progress-bar
            :value="quota.leftWeight"
            :precision="0"
            show-value
            class="remaining-bar"
          >
          </b-progress-bar>
        </b-progress>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "QuotaBar",
  filters: {
    round(value) {
      return Math.round(value);
    }
  },
  computed: {
    ...mapGetters(["calculateQuotas"])
  }
};
</script>

<style lang="scss" scoped>
.center {
  text-align: center;
}

.quotaLabel {
  margin-bottom: 2px;
}

.remaining-bar {
  background: $darkColor;
}

.quota {
  border-style: solid;
  border-width: 2px;
  background-color: #fff;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
  border-color: $darkColor;
}
</style>
