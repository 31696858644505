<template>
  <div name="BaitStep" class="list">
    <h3 class="step-title">
      <span>
        <span>
          <span>{{ $t("editTrip.baitStep.title") }}</span>
        </span>
      </span>
    </h3>
    <fieldset>
      <BaitUsed
        :baitUsages="form.baitUsages"
        :addError="
          (error, index) => {
            this.addError(error, index);
          }
        "
        :showErrorData="showErrorData"
        origin="trip"
      />
    </fieldset>
    <BaitFishedManager
      v-if="hasBaitFished"
      :baits="form.baitsFished"
      :addError="e => this.addPageError(e)"
      :showErrorData="showErrorData"
      @update-baitsFished="updateBaitsFished"
    />
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import BaitUsed from "./EffortStepComponents/BaitUsed.vue";
import BaitFishedManager from "@/components/subformEditTrip/BaitFishedManager.vue";

export default {
  components: {
    BaitUsed,
    BaitFishedManager
  },
  name: "BaitStep",
  data: () => ({
    form: {
      baitUsages: [],
      baitsFished: []
    },
    showErrorData: 0,
    name: "baitStep"
  }),
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      openTrip: state => state.currentOpenTrip,
      isFinalReview: state => state.editTripSubform.isFinalReview,
      showError: state => state.editTripSubform.showError,
      subform: state => state.editTripSubform.subform,
      currentPage: state => state.editTripSubform.currentPage
    }),
    hasBaitFished() {
      return this.getPropertyValue("hasBaitFished", {
        subforms: this.subform
      });
    }
  },
  watch: {
    form: {
      deep: true,
      handler: function() {
        this.$emit("update-form", this.form);
      }
    },
    showError() {
      if (this.isFinalReview || this.currentPage === this.name) {
        ++this.showErrorData;
      }
    }
  },
  methods: {
    ...mapActions(["addErrorsToState"]),
    updateBaitsFished(baits) {
      this.form.baitsFished = baits;
    },
    addError(error, index) {
      this.addErrorsToState({ page: `${this.name}-${index}`, errors: error });
      this.addErrorsToState({ page: this.name, errors: error });
    },
    addPageError(error) {
      this.addErrorsToState({ page: this.name, errors: error });
    }
  },
  beforeMount() {
    if ("creation" in this.openTrip) {
      this.form.baitUsages = this.openTrip.baitUsages ?? [];
      this.form.baitsFished = this.openTrip.baitsFished ?? [];
    }
  }
};
</script>
