<template>
  <div class="page insideApp">
    <form class="editTripForm">
      <h1>{{ $t("header.widgetTest") }}</h1>
      <h2>Vue component: {{ $options.name }}</h2>
      <br />
      <fieldset>
        <UnitConverter
          v-model="form.UnitConverter.weight"
          :min="-10"
          :max="9999999999"
          :refresh="refeshError"
          label="UnitConverter-weight"
          unit="weight"
          required
          @error="checkError"
        />
        <UnitConverter
          v-model="form.UnitConverter.m"
          :min="-10"
          :max="9999999999"
          :refresh="refeshError"
          label="UnitConverter-m"
          unit="m"
          @error="checkError"
        />
        <UnitConverter
          v-model="form.UnitConverter.cm"
          :min="-10"
          :max="9999999999"
          :refresh="refeshError"
          label="UnitConverter-cm"
          unit="cm"
          required
          @error="checkError"
        />
        <UnitConverter
          v-model="form.UnitConverter.mm"
          :min="-10"
          :max="9999999999"
          :refresh="refeshError"
          label="UnitConverter-mm"
          unit="mm"
          @error="checkError"
        />
        <UnitConverter
          v-model="form.UnitConverter.temperature"
          :min="-10"
          :max="9999999999"
          :refresh="refeshError"
          help="Text Help"
          label="UnitConverter-temperature"
          unit="temperature"
          required
          @error="checkError"
        />
        <UnitConverter
          v-model="form.UnitConverter.depth"
          :min="-10"
          :max="9999999999"
          :refresh="refeshError"
          label="UnitConverter-depth"
          unit="depth"
          @error="checkError"
        />
        <GenericInput
          v-model="form.GenericInput.text1"
          :minlength="0"
          :maxlength="50"
          :refeshError="refeshError"
          help="Text Help"
          label="GenericInput-text 1"
          type="text"
          required
          @error="checkError"
        />
        <GenericInput
          v-model="form.GenericInput.text2"
          :minlength="0"
          :maxlength="60"
          :refeshError="refeshError"
          label="GenericInput-text 2"
          type="text"
          @error="checkError"
        />
        <GenericInput
          v-model="form.GenericInput.tags1"
          :help="$t('editTrip.gearLossStep.tagsHelp')"
          :max="10"
          :refeshError="refeshError"
          label="GenericInput-tags 1"
          type="tags"
          required
          @error="checkError"
        />
        <GenericInput
          v-model="form.GenericInput.tags2"
          :help="$t('editTrip.gearLossStep.tagsHelp')"
          :max="5"
          :refeshError="refeshError"
          label="GenericInput-tags 2"
          type="tags"
          @error="checkError"
        />
        <GenericInput
          v-model="form.GenericInput.port1"
          :areasandports="systemsLists.areasandports"
          :refeshError="refeshError"
          help="Text Help"
          label="GenericInput-regionsubregion 1"
          type="regionsubregion"
          required
          @error="checkError"
        />
        <GenericInput
          v-model="form.GenericInput.port2"
          :areasandports="systemsLists.areasandports"
          :refeshError="refeshError"
          label="GenericInput-regionsubregion 2"
          type="regionsubregion"
          @error="checkError"
        />
        <GenericInput
          v-model="form.GenericInput.email1"
          :maxlength="50"
          :refeshError="refeshError"
          help="Text Help"
          label="GenericInput-email 1"
          type="email"
          required
          @error="checkError"
        />
        <GenericInput
          v-model="form.GenericInput.email2"
          :maxlength="30"
          :refeshError="refeshError"
          label="GenericInput-email 2"
          type="email"
          @error="checkError"
        />
        <GenericInput
          v-model="form.GenericInput.phone1"
          :maxlength="25"
          :refeshError="refeshError"
          help="Text Help"
          label="GenericInput-phone 1"
          type="phone"
          required
          @error="checkError"
        />
        <GenericInput
          v-model="form.GenericInput.phone2"
          :maxlength="11"
          :refeshError="refeshError"
          label="GenericInput-phone 2"
          type="phone"
          @error="checkError"
        />
        <GenericInput
          v-model="form.GenericInput.datalist1"
          :options="systemsLists.licenceSubtype"
          :maxlength="50"
          :refeshError="refeshError"
          help="Text Help"
          label="GenericInput-datalist 1"
          type="datalist"
          otherValue
          required
          @error="checkError"
        />
        <GenericInput
          v-model="form.GenericInput.datalist2"
          :options="systemsLists.weatherConditions"
          :maxlength="30"
          :refeshError="refeshError"
          label="GenericInput-datalist 2"
          type="datalist"
          otherValue
          @error="checkError"
        />
        <DecimalInput
          v-model="form.DecimalInput.decimal1"
          :min="-10"
          :max="999.9"
          :maxDecimal="1"
          :refeshError="refeshError"
          help="Text Help"
          label="Decimal 1"
          required
          @error="checkError"
        />
        <DecimalInput
          v-model="form.DecimalInput.decimal2"
          :min="-10"
          :max="999.9"
          :refeshError="refeshError"
          label="Decimal 2"
          @error="checkError"
        />
        <IntegerInput
          v-model="form.IntegerInput.integer1"
          :min="-10"
          :max="99999999"
          :refeshError="refeshError"
          help="Text Help"
          label="IntegerInput 1"
          required
          @error="checkError"
        />
        <IntegerInput
          v-model="form.IntegerInput.integer2"
          :min="-10"
          :max="99999999"
          :refeshError="refeshError"
          label="IntegerInput 2"
          @error="checkError"
        />
        <Select
          v-model="form.Select.select1"
          :options="systemsLists.populationDensity"
          :refeshError="refeshError"
          help="Text Help"
          label="Select 1"
          required
          @error="checkError"
        />
        <Select
          v-model="form.Select.select2"
          :options="systemsLists.fishingType"
          :refeshError="refeshError"
          label="Select 2"
          @error="checkError"
        />
        <PasswordInput
          v-model="form.Password.input1"
          :refeshError="refeshError"
          autocomplete="username"
          help="Text Help"
          label="Password 1"
          displayPassword
          required
          @error="checkError"
        />
        <PasswordInput
          v-model="form.Password.input2"
          :refeshError="refeshError"
          autocomplete="username"
          label="Password 2"
          @error="checkError"
        />
        <RadioInput
          v-model="form.RadioInput.required"
          :options="systemsLists.response"
          :refeshError="refeshError"
          help="Text Help"
          label="RadioInput 1"
          required
          @error="checkError"
        />
        <RadioInput
          v-model="form.RadioInput.optional"
          :options="systemsLists.response"
          :refeshError="refeshError"
          label="RadioInput 2"
          @error="checkError"
        />
        <RadioInput
          v-model="form.RadioInput.radio3Elements"
          :options="radioInput3elements"
          :refeshError="refeshError"
          help="Text Help"
          label="RadioInput 3"
          required
          @error="checkError"
        />
        <RadioInput
          v-model="form.RadioInput.radio3ElementsDescription"
          :options="
            addDescription(radioInput3elements, [
              {
                description: 'Description for item 1',
                value: 1
              },
              {
                description: 'Description for item 2',
                value: 2
              },
              {
                description:
                  'Description for item 3, this a a large text that will help to check correct field view',
                value: 3
              }
            ])
          "
          :refeshError="refeshError"
          help="Text Help"
          label="RadioInput 4"
          @error="checkError"
        />
        <br />
        <DateInput
          v-model="form.DateInput.date1"
          :refeshError="refeshError"
          label="DateInput 1"
          help="This is help text"
          required
          autodatetime
          passDate
          @error="checkError"
        />
        <DateInput
          v-model="form.DateInput.date2"
          :refeshError="refeshError"
          label="DateInput 2"
          help="This is help text"
          passDate
          @error="checkError"
        />
        <DateInput
          v-model="form.DateInput.date3"
          :refeshError="refeshError"
          label="DateInput 3"
          help="This is help text"
          hideTime
          required
          autodatetime
          passDate
          @error="checkError"
        />
        <DateInput
          v-model="form.DateInput.date4"
          :refeshError="refeshError"
          label="DateInput 4"
          help="This is help text"
          hideTime
          passDate
          @error="checkError"
        />
        <GeopositionWidget
          :position="form.GeopositionWidget.pos1"
          :refeshError="refeshError"
          :minLat="[38.0]"
          :maxLat="[72.0]"
          :minLong="[-148.0]"
          :maxLong="[-40.0]"
          :hasNafo="2"
          :hasGrid="2"
          required
          @error="checkError"
          @binding="
            function changeValue(value) {
              form.GeopositionWidget.pos1 = {
                ...form.GeopositionWidget.pos1,
                ...value
              };
            }
          "
        />
        <GeopositionWidget
          :position="form.GeopositionWidget.pos2"
          :refeshError="refeshError"
          :minLat="[-90]"
          :maxLat="[+90]"
          :minLong="[-180]"
          :maxLong="[+180]"
          :hasNafo="1"
          :hasGrid="1"
          @error="checkError"
          @binding="
            function changeValue(value) {
              form.GeopositionWidget.pos1 = {
                ...form.GeopositionWidget.pos2,
                ...value
              };
            }
          "
        />
        <SoakDuration
          v-model="form.SoakDuration.duration1"
          :soakDurationMax="1440 * 5"
          :refeshError="refeshError"
          :useDays="true"
          :required="true"
          @error="checkError"
        />
        <SoakDuration
          v-model="form.SoakDuration.duration2"
          :soakDurationMax="1440 * 2"
          :refeshError="refeshError"
          @error="checkError"
        />
        <SoakDuration
          v-model="form.SoakDuration.duration3"
          :soakDurationMax="1440 * 3"
          :refeshError="refeshError"
          :required="true"
          @error="checkError"
        />
        <SoakDuration
          v-model="form.SoakDuration.duration4"
          :soakDurationMax="1440 * 5"
          :refeshError="refeshError"
          :useDays="true"
          @error="checkError"
        />
        <p class="field-wrapper">
          <span class="label">
            <span>
              <label> Experimental Input 1</label>
            </span>
          </span>
          <ExperimentalInput
            v-model="form.ExperimentalInput.input1"
            :required="true"
            :min="3"
            :max="50"
            :digits="true"
            :refeshError="refeshError"
            type="number"
          />
        </p>
        <p class="field-wrapper">
          <span class="label">
            <span>
              <label> Experimental Input 2</label>
            </span>
          </span>
          <ExperimentalInput
            v-model="form.ExperimentalInput.input2"
            :min="5"
            :max="10"
            :digits="true"
            :refeshError="refeshError"
            type="number"
          />
        </p>
      </fieldset>
      <p class="field-wrapper">
        <input
          :value="$t('tests.testError')"
          type="button"
          class="removeSuppEntryButton form-control action-btn-delete"
          @click="testError"
        />
      </p>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GenericInput from "@/components/GenericInput.vue";
import DecimalInput from "@/components/subformEditTrip/widgets/DecimalInput.vue";
import IntegerInput from "@/components/subformEditTrip/widgets/IntegerInput.vue";
import Select from "@/components/Select.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";
import PasswordInput from "@/components/widgets/PasswordInput.vue";
import GeopositionWidget from "@/components/widgets/GeopositionWidget.vue";
import RadioInput from "@/components/widgets/RadioInput.vue";
import DateInput from "@/components/widgets/DateInput.vue";
import SoakDuration from "@/components/subformEditTrip/widgets/SoakDuration.vue";
import ExperimentalInput from "@/components/ExperimentalInput.vue";

export default {
  name: "WidgetTest",
  components: {
    DecimalInput,
    ExperimentalInput,
    GenericInput,
    IntegerInput,
    Select,
    UnitConverter,
    PasswordInput,
    RadioInput,
    DateInput,
    GeopositionWidget,
    SoakDuration
  },
  data: function() {
    return {
      error: {},
      refeshError: 0,
      radioInput3elements: [
        { value: 1, text: "item1" },
        { value: 2, text: "item2" },
        { value: 3, text: "item3" }
      ],
      form: {
        GenericInput: {
          text1: null,
          text2: null,
          port1: null,
          port2: null,
          tags1: null,
          tags2: null,
          phone1: null,
          phone2: null,
          email1: null,
          email2: null,
          datalist1: null,
          datalist2: null
        },
        DateInput: {
          date1: "",
          date2: "",
          date3: "",
          date4: ""
        },
        DecimalInput: {
          decimal1: null,
          decimal2: null
        },
        IntegerInput: {
          integer1: null,
          integer2: null
        },
        Select: {
          select1: null,
          select2: null
        },
        RadioInput: {
          required: null,
          optional: null,
          radio3Elements: null,
          radio3ElementsDescription: null
        },
        UnitConverter: {
          weight: null,
          m: null,
          cm: null,
          mm: null,
          temperature: null,
          depth: null
        },
        Password: {
          input1: null,
          input2: null
        },
        GeopositionWidget: {
          pos1: null,
          pos2: null
        },
        SoakDuration: {
          duration1: null,
          duration2: null,
          duration3: null,
          duration4: null
        },
        ExperimentalInput: {
          input1: null,
          input2: null
        }
      }
    };
  },
  computed: {
    ...mapState({
      systemsLists: state => state.systemsLists
    })
  },
  methods: {
    checkError(value) {
      this.error = { ...this.error, ...value };
    },
    testError() {
      const vuejs = this;
      ++this.refeshError;
      vuejs.$nextTick(() => {
        vuejs.$hasInputErrorAndScroll();
      });
    },
    addDescription(originalArray, descriptionArray) {
      const newArray = originalArray.map(item => ({ ...item }));
      newArray.forEach(item => {
        const match = descriptionArray.find(desc => desc.value === item.value);
        if (match) {
          item.description = match.description;
        }
      });
      return newArray;
    }
  }
};
</script>
